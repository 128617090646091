


































import {
  defineComponent,
  computed,
  reactive,
  PropType,
} from "@vue/composition-api"
import { DialogType } from "./MemoTemplateDialog.vue"

interface State {
  showMenu: boolean
}

export default defineComponent({
  props: {
    bordered: Boolean,
    height: [String, Number],
    width: [String, Number],
    isActive: {
      type: Boolean,
      required: true,
    },
    type: String as PropType<DialogType>,
  },

  setup(props, { emit }) {
    const state = reactive<State>({
      showMenu: false,
    })
    const apply = () => {
      emit("clickApply")
      state.showMenu = false
    }

    const clear = () => {
      emit("clickClear")
      state.showMenu = false
    }

    const openDialog = () => {
      emit("clickOpenDialog")
    }

    const items = computed(() => [
      {
        text: `${props.isActive ? "適用解除" : "適用"}`,
        action: props.isActive ? clear : apply,
      },
      {
        text: `${props.type === DialogType.Common ? "閲覧" : "編集"}`,
        action: openDialog,
      },
    ])

    return { state, items }
  },
})
