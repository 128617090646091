













































































































import {
  defineComponent,
  reactive,
  watch,
  PropType,
  computed,
  ref,
} from "@vue/composition-api"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import { i18nContainer } from "@/containers/I18nContainer"
import TextField from "@/components/textFields/TextField.vue"
import TextArea from "@/components/textAreas/TextArea.vue"
import RichEditor from "@/components/textAreas/RichEditor.vue"
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue"

export interface MemoInfo {
  id: string
  name: string
  body: string
  type: DialogType
}

export enum DialogType {
  New = "new", //新規テンプレート (テンプレ内容編集可)
  Common = "common", //共通テンプレート (編集不可)
  Custom = "custom", //カスタムテンプレート (編集可)
}

interface State {
  isFormValid: boolean
  memoTempBody: string
  memoInfo: MemoInfo
  errorMsg: string
  isOpenConfirmDialog: boolean
}

export default defineComponent({
  components: {
    DefaultButton,
    I18nFormattedMessage,
    DefaultDialog,
    TextField,
    TextArea,
    RichEditor,
    ConfirmDialog,
  },
  props: {
    disabled: Boolean,
    visible: Boolean,
    memoInfo: { type: Object as PropType<MemoInfo>, required: true },
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      isFormValid: false,
      memoTempBody: "",
      memoInfo: Object.assign({}, props.memoInfo),
      errorMsg: "",
      isOpenConfirmDialog: false,
    })

    const { formatMessage } = i18nContainer.useContainer()

    const onChangeForm = (isValid: boolean) => {
      state.isFormValid = isValid
    }

    const onCancel = () => {
      if (
        props.memoInfo.body !== state.memoInfo.body ||
        props.memoInfo.name !== state.memoInfo.name
      )
        emit("cancel")
      else emit("cancelNotEdit")
    }

    const onOk = () => {
      if (!state.memoInfo.body || state.memoInfo.body === "<p></p>") {
        state.errorMsg = formatMessage({
          id: "MemoTemplateDialog.errorMsg.bodyRequired",
          defaultMessage:
            "テンプレート内容を入力してください。（内容は後から変更出来ます）",
        })
        return
      }
      emit("ok", state.memoInfo)
    }

    const onDelete = () => {
      state.isOpenConfirmDialog = true
    }

    const onConfirmDialogCancel = () => {
      state.isOpenConfirmDialog = false
    }

    const onConfirmDialogOk = () => {
      emit("onDelete", state.memoInfo)
      state.isOpenConfirmDialog = false
    }

    const isEditMode = computed<boolean>(
      () => props.memoInfo.type !== DialogType.Common
    )

    const isNew = computed<boolean>(
      () => props.memoInfo.type === DialogType.New
    )

    const onBodyChange = (html: string) => {
      state.memoInfo.body = html
    }

    const memoTemplateDialogForm = ref<InstanceType<typeof HTMLFormElement>>()
    const resetValidation = () => {
      if (!memoTemplateDialogForm.value) return
      memoTemplateDialogForm.value.resetValidation()
    }

    watch(
      () => props.memoInfo,
      newValue => {
        if (!newValue) return
        state.memoInfo = Object.assign({}, newValue)
        state.memoTempBody = state.memoInfo.body
      },
      { deep: true }
    )

    watch(
      () => props.visible,
      newValue => {
        if (newValue) {
          state.memoTempBody = props.memoInfo.body
          state.memoInfo.name = props.memoInfo.name
          state.memoInfo.body = props.memoInfo.body
        } else {
          resetValidation()
          state.errorMsg = ""

          //""にするとRichEditorのwatchがトリガーされないため、空白文字をセットする。
          //次にダイアログ開く際にはメモ本文がリセットされるので問題ない
          state.memoTempBody = " "

          state.memoInfo.name = ""
          state.memoInfo.body = ""
        }
      }
    )

    return {
      state,
      isEditMode,
      onChangeForm,
      onCancel,
      onOk,
      onDelete,
      onConfirmDialogCancel,
      onConfirmDialogOk,
      i18nFormattedMessage: formatMessage,
      isNew,
      onBodyChange,
      memoTemplateDialogForm,
    }
  },
})
